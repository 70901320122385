import axios from "@axios";

export default {
    namespaced: true,
    state: {
        listsAnimalStatusOuts: [],
    },
    getters: {
        getListsAnimalStatusOuts: (state) => state.listsAnimalStatusOuts,
    },
    mutations: {
        SET_LISTS_ANIMAL_STATUS(state, value) {
            state.listsAnimalStatusOuts = value;
        },
    },
    actions: {
        getAnimalStatusOuts(ctx, queryParams) {
            console.log(ctx);
            return new Promise((resolve, reject) => {
                axios
                    .get("/status-outs")
                    .then((response) => {
                        resolve(response);
                        ctx.commit(
                            "SET_LISTS_ANIMAL_STATUS",
                            response.data.data
                        );
                    })
                    .catch((error) => reject(error));
            });
        },
        postAnimalStatusOuts(ctx, name) {
            // console.log(name)
            return new Promise((resolve, reject) => {
                axios
                    .post("/status-outs", { name })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
        putAnimalStatusOuts(ctx, { id, name }) {
            // console.log(id, name);
            return new Promise((resolve, reject) => {
                axios
                    .put(`/status-outs/${id}`, { name })
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },

        deleteAnimalStatusOuts(ctx, { id }) {
            // console.log(id, name);
            return new Promise((resolve, reject) => {
                axios
                    .delete(`/status-outs/${id}`)
                    .then((response) => resolve(response))
                    .catch((error) => reject(error));
            });
        },
    },
};
