<template>
  <b-card no-body class="overflow-hidden">
    <b-row class="px-2 py-1" align-v="center">
      <b-col cols="auto" class="mr-auto">
        <h4>Status Keaktifan Hewan SmartFarm</h4>
      </b-col>

      <b-col cols="auto" class="">
        <b-button variant="primary" v-b-toggle.sidebar-add>
          <feather-icon icon="PlusIcon" />
          <span>Add Record</span>
        </b-button>
        <b-sidebar
          id="sidebar-add"
          right
          shadow
          bg-variant="white"
          title="Add New Animal Status"
        >
          <div class="p-2">
            <animal-status-outs-add-new />
          </div>
        </b-sidebar>
      </b-col>
    </b-row>
    <hr />
    <div class="px-2 py-1">
      <b-col cols="12" md="3" class="">
        <b-form-input
          v-model="searchQueryRawMilkSummary"
          class="d-inline-block"
          placeholder="Search..."
        />
      </b-col>
    </div>
    <div>
      <b-table responsive="sm" :items="listsAnimalStatus" :fields="fields">
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              :to="{
                name: 'milkProductDetail',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Detail Pencatatan</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-b-toggle.sidebar-edit
              @click="getAnimalStatus(data.item)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item @click="deleteAnimalStatus(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </div>
    <b-sidebar
      id="sidebar-edit"
      right
      shadow
      bg-variant="white"
      title="Edit Animal Status"
    >
      <div class="p-2">
        <animal-status-outs-edit :AnimalStatus="AnimalStatus" />
      </div>
    </b-sidebar>
  </b-card>
</template>

<script>
import {
  BButton,
  BSidebar,
  VBToggle,
  BTable,
  BCard,
  BAvatar,
  BRow,
  BCol,
  BFormInput,
  BDropdown,
  BDropdownItem,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import store from "@/store";
import { mapGetters } from "vuex";
import AnimalStatusOutsAddNew from "./AnimalStatusOutsAddNew.vue";
import AnimalStatusOutsEdit from "./AnimalStatusOutsEdit.vue";
import AnimalStatusOutsStoreModule from "./AnimalStatusOutsStoreModule";
import { onUnmounted } from "@vue/composition-api";

export default {
  components: {
    BButton,
    BSidebar,
    BTable,
    BCard,
    BAvatar,
    BRow,
    BCol,
    BFormInput,
    BDropdown,
    BDropdownItem,

    AnimalStatusOutsAddNew,
    AnimalStatusOutsEdit,
  },
  setup() {
    const ANIMAL_STATUS_STORE_MODULE_NAME = "app-animal-status-outs";

    // Register module
    if (!store.hasModule(ANIMAL_STATUS_STORE_MODULE_NAME))
      store.registerModule(
        ANIMAL_STATUS_STORE_MODULE_NAME,
        AnimalStatusOutsStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ANIMAL_STATUS_STORE_MODULE_NAME))
        store.unregisterModule(ANIMAL_STATUS_STORE_MODULE_NAME);
    });
  },
  directives: {
    "b-toggle": VBToggle,
  },
  data() {
    return {
      fields: ["id", "name", "actions"],
      AnimalStatus: {},
    };
  },
  computed: {
    ...mapGetters({
      listsAnimalStatus: "app-animal-status-outs/getListsAnimalStatusOuts",
    }),
  },
  created() {
    store.dispatch("app-animal-status-outs/getAnimalStatusOuts");
  },
  methods: {
    getAnimalStatus(item) {
      // console.log(name);
      this.AnimalStatus = item;
    },
    deleteAnimalStatus(id) {
      store
        .dispatch("app-animal-status-outs/deleteAnimalStatusOuts", { id })
        .then((response) => {
          store.dispatch("app-animal-status-outs/getAnimalStatusOuts");
        })
        .catch((err) => console.log(err.response));
    },
  },
};
</script>
